import axios from 'axios'
import { PaymentMethod, PaymentTerm } from '../definition'
export * from './event-emitter'
export * from './url-util'
export * from './enum-util'
export * from './quote-caculation'

export const nonceFunction: any = () => {}

const tempPreventMouseEvent = (callback: Function, event: React.MouseEvent) => {
  event.preventDefault()
  callback()
}
export const preventMouseEvent = (callback: Function) => {
  return tempPreventMouseEvent.bind(null, callback)
}

export const fetchImage = async (imageUrl: string) => {
  const response = await axios.get(imageUrl, { responseType: 'blob' })
  return URL.createObjectURL(response.data)
}

export const getImageSize = (file: File): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    let objectUrl = URL.createObjectURL(file)
    img.onload = function () {
      const self = this as any
      resolve({ width: self.width, height: self.height })
    }
    img.onerror = function (error) {
      reject(error)
    }
    img.src = objectUrl
  })
}

export const putToS3 = async (fileObject: File, presignedUrl: string) => {
  const requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': fileObject.type
    },
    body: fileObject
  }
  return await fetch(presignedUrl, requestOptions)
}

class EnumUtil {
  static getNamesAndValues<T extends string | number>(enumType: any): { name: string; value: T }[] {
    return this.getNames(enumType).map((_name) => {
      return { name: _name, value: enumType[_name] as T }
    })
  }

  static getNames(enumType: any): string[] {
    return Object.keys(enumType).filter((key) => isNaN(+key))
  }

  static getNameFromValue<T extends string | number>(enumType: any, value: T): string | null {
    const all = this.getNamesAndValues(enumType).filter((pair) => pair.value === value)
    return all.length === 1 ? all[0].name : null
  }

  static getValues<T extends string | number>(enumType: any): T[] {
    return this.getNames(enumType).map((name) => enumType[name]) as T[]
  }
}
export const getEnumValues = (enumType: any) => EnumUtil.getValues(enumType)

export const getPaymentTermLlabel = (paymentTerm: PaymentTerm | undefined) => {
  if (!paymentTerm) {
    return ''
  }
  let label
  switch (paymentTerm) {
    case PaymentTerm.COD:
      label = 'Cash On Delivery'
      break
    case PaymentTerm.CREDIT:
      label = 'Credit Terms'
      break
    case PaymentTerm.PPP:
      label = 'Partial Pre-Payment '
      break
    case PaymentTerm.FPP:
      label = 'Full Pre-Payment'
      break
    case PaymentTerm.OTHER:
      label = 'Others'
      break
    default:
    // code block
  }
  return label
}

export const getPaymentMethodlabel = (paymentMethod: PaymentMethod | undefined) => {
  if (!paymentMethod) {
    return ''
  }
  let label
  switch (paymentMethod) {
    case PaymentMethod.PAYNOW:
      label = 'Paynow'
      break
    case PaymentMethod.CASH:
      label = 'Cash'
      break
    case PaymentMethod.CARD:
      label = 'Card Payment'
      break
    default:
    // code block
  }
  return label
}

export function replaceURLs(message: string) {
  if (!message) return '<></>'

  var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g
  return message.replace(urlRegex, function (url) {
    var hyperlink = url
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'http://' + hyperlink
    }
    const result = '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    return result
  })
}

export const getBase64FromUrl = async (url?: string) => {
  if (url) {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  } else {
    return ''
  }
}

export const estimateLines = (text: string | undefined, characterPerLine: number) => {
  if (text) {
    return Math.floor(text.length / characterPerLine)
  } else {
    return 1
  }
}

export const getStartOfDay = (date: string) => {
  const start = new Date(date)
  start.setUTCHours(0, 0, 0, 0)
  return start.toISOString()
}

export const getEndOfDay = (date: string) => {
  const end = new Date(date)
  end.setUTCHours(23, 59, 59, 999)
  return end.toISOString()
}

export const selectFilterOption = (input: string, option?: { label?: string; value?: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
