import axios from 'axios'
import { JWT_TOKEN } from '../constants'
import { CurrentAccountInfoResponseDto } from '../definition'
import { ENV } from '../initialization'

const DOMAIN = ENV.BACKEND_PREFIX_URL

export const asyncGetCurrentAccountInformation = async (): Promise<CurrentAccountInfoResponseDto | undefined> => {
  const token = window.localStorage.getItem(JWT_TOKEN)
  if (token) {
    const response = await axios.get(`${ENV.BACKEND_PREFIX_URL}/api/authentication/info`)
    return response.data
  }
}

export const asyncLogin = async (email: string, password: string, rememberMe?: boolean): Promise<void> => {
  try {
    const response = await axios.post(`${DOMAIN}/api/authentication/login`, {
      email,
      password,
      rememberMe
    })
    const token = response.data?.token
    if (token) {
      window.localStorage.setItem(JWT_TOKEN, token)
    }
  } catch (error: any) {
    throw error
  }
}

export const asyncForgotPassword = async (email: string): Promise<void> => {
  await axios.post(`${DOMAIN}/api/authentication/forgot-password`, { email })
}

export const asyncResetPassword = async (token: string, email: string, password: string): Promise<void> => {
  await axios.post(`${DOMAIN}/api/authentication/reset-password`, {
    token,
    email,
    password
  })
}

export const asyncLogout = async (): Promise<void> => {
  await axios.post(`${DOMAIN}/api/authentication/logout`)
  window.localStorage.removeItem(JWT_TOKEN)
}

export const asyncChangePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
  await axios.post(`${DOMAIN}/api/authentication/change-password`, {
    oldPassword,
    newPassword
  })
  window.localStorage.removeItem(JWT_TOKEN)
}

export const asyncChangeEmail = async (email: string): Promise<void> => {
  await axios.put(`${DOMAIN}/api/authentication/update-admin`, {
    email
  })
}
